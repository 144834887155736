<template>
  <Head v-if="data">
    <title>{{ data.content[0].title }} | Новости {{ $titleEnd }}</title>
    <meta name="description" :content="firstParagraph" />
    <meta property="og:image" :content="data.preview.image_desktop" />
    <meta property="og:type" content="article" />
    <meta
      property="og:title"
      :content="data.content[0].title + ' | Новости ' + $titleEnd"
    />
    <meta property="og:description" :content="firstParagraph" />
  </Head>
  <main class="main">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 offset-xl-2 offset-lg-1">
          <Breadcrumbs
            v-if="data"
            :links="[
              { name: 'Home', text: 'Главная' },
              { name: 'News', text: 'Новости' },
              { text: data.content[0].title },
            ]"
          />
          <div
            v-if="data"
            class="news-inner"
            itemscope
            itemtype="https://schema.org/Article"
          >
            <meta
              v-if="data.preview"
              itemprop="image"
              :content="data.preview?.image_desktop"
            />
            <meta
              v-if="data.projects"
              itemprop="author"
              :content="data.projects[0].name"
            />
            <meta itemprop="mainEntityOfPage" :content="url" />
            <meta itemprop="url" :content="url" />
            <span
              v-if="data.projects"
              itemprop="publisher"
              itemscope
              itemtype="https://schema.org/Organization"
            >
              <span
                itemprop="logo"
                itemscope
                itemtype="https://schema.org/ImageObject"
              >
                <meta
                  itemprop="image"
                  content="https://very-botsad.ru/i/logo.svg"
                />
              </span>
              <meta itemprop="name" :content="data.projects[0].name" />
            </span>
            <div class="date" itemprop="datePublished">
              {{ $filters.dateFormat(data.published_at) }}
            </div>
            <h1 class="h3" itemprop="headline">
              {{ data.content[0].title }}
            </h1>
            <div
              class="page-content"
              itemprop="articleBody"
              v-html="data.content[0].body"
            />
            <div v-if="related.length > 0" class="related">
              <div class="related__title h4">Больше новостей</div>
              <div class="row">
                <div v-for="item in related" :key="item.id" class="col-sm-6">
                  <NewsItem :item="item" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import NewsItem from "@/components/news/NewsItem.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Breadcrumbs,
    NewsItem,
  },
  data() {
    return {
      data: null,
      related: [],
    };
  },
  computed: {
    url() {
      return window.location.origin + "" + this.$route.path;
    },
    // content() {
    // 	let content = '';
    // 	if (this.data) {
    // 		let count = 0;
    // 		this.data.content[0].body.blocks.forEach(block => {
    // 			if (block.type == 'paragraph') {
    // 				let bigClass = '';
    // 				if (count == 0) {
    // 					bigClass = ' class="text-big"';
    // 				}
    // 				content += '<p' + bigClass + '>' + block.data.text + '</p>';
    // 				count++;
    // 			} else if (block.type == 'image') {
    // 				content +=
    // 					'<p><img src="' +
    // 					block.data[0].image_desktop +
    // 					'" width="1200" height="800" loading="lazy" alt="' +
    // 					this.title +
    // 					'" /></p>';
    // 			}
    // 		});
    // 	}
    // 	return content;
    // },
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint +
          "publication/slug/" +
          this.$route.params.slug +
          "/?type=html"
      );

      this.data = response.data.data;

      let related = await axios.get(
        this.$endpoint +
          "publications?project=9&category=1&language=ru&page=1&per_page=5"
      );
      related = related.data.data.filter((item) => {
        return item.id !== this.data.id;
      });
      related = related.slice(0, 4);
      this.related = related;
    } catch (err) {
      this.$Progress.fail();
      this.$router.push({name: 'NotFound'})
    } finally {
      this.$Progress.finish();
    }
  },
  computed: {
    firstParagraph() {
      return this.data?.content[0].body.split("</p>")[0].replace("<p>", "");
    },
  },
};
</script>

<style scoped>
.news-inner {
  margin-top: 30px;
}

.date {
  margin-bottom: 17px;
  color: #9c9b9b;
}

.related {
  margin-top: 80px;
  margin-bottom: -65px;
}

.related__title {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .news-inner {
    margin-top: 20px;
  }

  .related {
    margin-top: 60px;
    margin-bottom: -40px;
  }

  .related__title {
    margin-bottom: 30px;
  }
}
</style>
